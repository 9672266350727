import ResponsiveContainer from "../components/Cards/ResponsiveContainer";
import Text from "../components/TextSection/Text";

const ProgramSection = () => {
    return (
        <>
            <div style={{ marginTop: 70, }}>

                <ResponsiveContainer
                    headingText="Timelines"
                    bgColor="#f0f0f0"
                    textAlign="center"
                    fontSize={48}
                    fontWeight={700}
                >
                    <div style={{
                        display: 'flex',
                        justifyContent: 'center', // Center horizontally
                        alignItems: 'flex-start', // Align items to the top
                        gap: '60px',              // Space between the two columns
                        padding: '20px',
                        paddingBottom: 60        // Padding around the container
                    }}>
                        <div style={{
                            display: 'flex',
                            flexDirection: 'column',
                            gap: '24px',   // Space between items in the first column
                        }}>
                            <div style={{ textAlign: 'left' }}>
                                <Text
                                    fontWeight={400}
                                    fontSize={20}
                                    fontFamily="Inter"
                                    color="#1E1E1E"
                                >
                                    Registrations Close:
                                </Text>
                            </div>
                            <div style={{ textAlign: 'left' }}>
                                <Text
                                    fontWeight={400}
                                    fontSize={20}
                                    fontFamily="Inter"
                                    color="#1E1E1E"
                                >
                                    Qualification Deadline:
                                </Text>
                            </div>
                            <div style={{ textAlign: 'left' }}>
                                <Text
                                    fontWeight={400}
                                    fontSize={20}
                                    fontFamily="Inter"
                                    color="#1E1E1E"
                                >
                                    Event Date:
                                </Text>
                            </div>
                        </div>

                        <div style={{
                            display: 'flex',
                            flexDirection: 'column',
                            gap: '24px',              // Space between items in the second column
                        }}>
                            <div style={{ textAlign: 'left' }}>
                                <Text
                                    fontWeight={400}
                                    fontSize={20}
                                    fontFamily="Inter"
                                    color="#1E1E1E"
                                >
                                    October 15, 2024
                                </Text>
                            </div>
                            <div style={{ textAlign: 'left' }}>
                                <Text
                                    fontWeight={400}
                                    fontSize={20}
                                    fontFamily="Inter"
                                    color="#1E1E1E"
                                >
                                    October 15, 2024
                                </Text>
                            </div>
                            <div style={{ textAlign: 'left' }}>
                                <Text
                                    fontWeight={400}
                                    fontSize={20}
                                    fontFamily="Inter"
                                    color="#1E1E1E"
                                >
                                    November 23, 2024
                                </Text>
                            </div>
                        </div>
                    </div>
                </ResponsiveContainer>
            </div>


            <div style={{ marginLeft: '2%', marginRight: '2%', marginTop: 50,fontStyle:'italic' }} >
                <Text
                    fontWeight={400}
                    fontSize={20}
                    fontFamily="Inter"
                    color="#C00F0C"
                >
                    Race details above are subject to minor changes before the event.
                </Text>
            </div>

            <span id="programschedule"></span>
            <div style={{ marginLeft: '2%', marginRight: '2%', marginTop: 20 }}  id="i1"  >

                <Text
                    fontWeight={400}
                    fontSize={17}
                    fontFamily="Inter"
                    color="black"
                >                    
                    {'Please check out the following link for more details about the run  '}
                </Text>
                <a href="https://malnadultra.com/" style={{fontWeight:'700'}}>https://malnadultra.com/</a>
            </div>
            <div style={{ display: 'flex', justifyContent: 'center' }} >
                <div               
                    style={{
                        width: '90%',
                        textAlign: 'center', // Align text inside the div to the left
                        padding: '20px', // Optional: Add padding for better visual
                    }}
                >
                    <Text
                        fontWeight={400}
                        fontSize={20}
                        fontFamily="Inter"
                        color="#757575"
                    //style={{ textTransform: 'uppercase' }} // Pass additional styles if needed
                    >
                        We're excited to offer a package for the upcoming Malnad Ultra Run with stay at newly built  “Navile” (means peacock). The property has stunning views and is just two kilometers away from the trail run. The package availability is limited, and it's on a first-come, first-serve basis!
                       
                    </Text>
                </div>
            </div>
            

            <div style={{ marginLeft: '2%', marginRight: '2%', marginTop: '2%' }}>
                <ResponsiveContainer
                    headingText="Package Itinerary"
                    // additionalText="This is some optional additional text."
                    bgColor="#f0f0f0"
                    textAlign="center"
                    fontSize={42}
                    fontWeight={700}
                >
                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                        <div
                            style={{
                                width: '650px',
                                textAlign: 'left', // Align text inside the div to the left
                                padding: '20px', // Optional: Add padding for better visual
                            }}
                        >
                            <Text
                                fontWeight={700}
                                fontSize={20}
                                fontFamily="Inter"
                                color="#1E1E1E"
                            >
                                November 22nd
                            </Text>
                            <div style={{ marginLeft: '5%' }}>
                                <ul>
                                    <li>    <Text
                                        fontWeight={400}
                                        fontSize={20}
                                        fontFamily="Inter"
                                        color="#757575"
                                    >Meet and Greet at Navile - from 11 AM
                                    </Text>
                                    </li>
                                    <li>    <Text
                                        fontWeight={400}
                                        fontSize={20}
                                        fontFamily="Inter"
                                        color="#757575"
                                    >Check-In
                                    </Text>
                                    </li>
                                    <li>    <Text
                                        fontWeight={400}
                                        fontSize={20}
                                        fontFamily="Inter"
                                        color="#757575"
                                    >Lunch
                                    </Text>
                                    </li>
                                    <li>    <Text
                                        fontWeight={400}
                                        fontSize={20}
                                        fontFamily="Inter"
                                        color="#757575"
                                    >Rest
                                    </Text>
                                    </li>
                                    <li>    <Text
                                        fontWeight={400}
                                        fontSize={20}
                                        fontFamily="Inter"
                                        color="#757575"
                                    >Meet at 5 PM for tea & Snacks, Introduce each other
                                    </Text>
                                    </li>
                                    <li>    <Text
                                        fontWeight={400}
                                        fontSize={20}
                                        fontFamily="Inter"
                                        color="#757575"
                                    >Local sightseeing between 3 - 5 pm based on interest
                                    </Text>
                                    </li>
                                    <li>    <Text
                                        fontWeight={400}
                                        fontSize={20}
                                        fontFamily="Inter"
                                        color="#757575"
                                    >Fun Activities - 6 pm onwards
                                    </Text>
                                    </li>
                                    <li>    <Text
                                        fontWeight={400}
                                        fontSize={20}
                                        fontFamily="Inter"
                                        color="#757575"
                                    >Dinner & Early to bed.
                                    </Text>
                                    </li>
                                </ul>
                            </div>

                            <Text
                                fontWeight={700}
                                fontSize={20}
                                fontFamily="Inter"
                                color="#1E1E1E"
                            >
                                November 23rd
                            </Text>
                            <div style={{ marginLeft: '5%' }}>
                                <ul>
                                    <li>    <Text
                                        fontWeight={400}
                                        fontSize={20}
                                        fontFamily="Inter"
                                        color="#757575"
                                    >Pick-up and Drop to the Run Start Point
                                    </Text>
                                    </li>
                                    <li>    <Text
                                        fontWeight={400}
                                        fontSize={20}
                                        fontFamily="Inter"
                                        color="#757575"
                                    >Lunch, Tea & Snacks, Dinner
                                    </Text>
                                    </li>
                                    <li>    <Text
                                        fontWeight={400}
                                        fontSize={20}
                                        fontFamily="Inter"
                                        color="#757575"
                                    >Bonfire
                                    </Text>
                                    </li>
                                </ul>
                            </div>

                            <Text
                                fontWeight={700}
                                fontSize={20}
                                fontFamily="Inter"
                                color="#1E1E1E"
                            >
                                November 24th
                            </Text>
                            <div style={{ marginLeft: '5%' }} >
                                <ul style={{ marginBottom: 0 }}>
                                    <li>    <Text
                                        fontWeight={400}
                                        fontSize={20}
                                        fontFamily="Inter"
                                        color="#757575"
                                    >Breakfast
                                    </Text>
                                    </li>
                                    <li>    <Text
                                        fontWeight={400}
                                        fontSize={20}
                                        fontFamily="Inter"
                                        color="#757575"
                                    >Check-Out by 1 pm
                                    </Text>
                                    </li>
                                </ul>
                                <span id="package"></span>
                            </div >
                        </div>
                    </div>
                </ResponsiveContainer>
            </div>
        </>
    )
}

export default ProgramSection;