import React from 'react'
import Text from '../../components/TextSection/Text'
import { Col, Row } from 'reactstrap'

function Support() {

    const styles = {
        email: {
          color: 'blue',
          fontWeight: 'bold',
          textDecoration: 'none',
        },
        phone: {
          color: 'blue',
          fontWeight: 'bold',
          textDecoration: 'none',
        },
      };

  return (
    <div><div style={{ padding: "10px", lineHeight: "1.6" }}>
    <Row nogutters>
      <Col
        xs={12}
        md={12}
        style={{
          textAlign: "left",
          marginTop: "0%",
          paddingRight: "20px",
         
        }}
      >
        <div style={{ marginTop: 4 }}>
          <Text
            fontWeight={600}
            fontSize={20}
            fontFamily="Inter"
            color="#1E1E1E"
          >
Support
          </Text>
        </div>
        <div
          style={{
            textAlign: "justify",
            paddingRight: "1%",
            lineHeight: "14px",
          }}
        >
          <Text
            fontWeight={400}
            fontSize={14}
            fontFamily="Inter"
            color="#1E1E1E"
          >
           Please contact{' '}
        <a href="mailto:adventra24@gmail.com" style={styles.email}>
          adventra24@gmail.com
        </a>{' '}
        or{' '}
        <a href="tel:+917204350092" style={styles.phone}>
          +91-72043 50092
        </a>{' '}
        for any support needed.
          </Text>
        </div>
        

      </Col>
    </Row>
  </div>
   </div>
  )
}

export default Support
